<template>
  <div id="access-data">
    <v-flex xs12>
      <h1 v-if="!isMobile">{{ $t('access-data.title') }}</h1>

      <div :class="!isMobile ? 'item mt-5' : 'item'">
        <h2> {{ $t('access-data.change-password') }} </h2>
        <p class="text">
          {{ $t('access-data.subtitle1') }}
        </p>
        <p class="text">
          {{ $t('access-data.subtitle2') }} <b> {{ userEmail }} </b>
        </p>

        <v-flex xs12 sm12 md12>
          <v-form
            ref="form"
            class="mt-9"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="old_password"
                  type="password"
                  ref="old_password"
                  :label=" $t('access-data.current-password')"
                  :rules="oldPassRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4 offset-md1>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="new_password"
                  type="password"
                  ref="new_password"
                  :label=" $t('access-data.new-password')"
                  :rules="newPassRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4 offset-md1>
                <v-text-field
                  v-model="new_password2"
                  type="password"
                  ref="new_password2"
                  :label=" $t('access-data.retype-password')"
                  :rules="newPass2Rules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4 :class="!isMobile ? 'mt-9' : 'mt-2'">
                <v-btn tile :color="bgColor" dark width="100%" height="44px" @click="submit()">
                  <span class="btn-text"> {{ $t('access-data.change-password') }} </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'AccessData',
    computed: {
      ...mapState([
        'isMobile',
        'userEmail',
        'token',
        'bgColor'
      ])
    },
    data () {
      return {
        valid: true,
        old_password: '',
        new_password: '',
        new_password2: '',
        oldPassRules: [
          v => !!v || this.$t('access-data.form.error-current'),
        ],
        newPassRules: [
          v => !!v || this.$t('access-data.form.error-new'),
        ],
        newPass2Rules: [
          v => !!v || this.$t('access-data.form.error-retype'),
          v => v === this.new_password || this.$t('access-data.form.error-not-match')
        ]
      }
    },
    methods: {
      submit () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            is_offline: true
          }

          axios
            .post('/api/v1/member/change_password', {
              old_password: this.old_password,
              new_password: this.new_password,
              confirm_new_password: this.new_password2
            })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)

              this.reset()
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      reset () {
        this.$refs.form.reset()
      }
    }, 
    mounted () {
      this.setMetaInfo(null, 'Access Data')
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('access-data.title'))
    }
  }
</script>

<style scoped>
  .item {
    color: #000000;
  }

  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
  }
</style>
